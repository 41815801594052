
import { defineComponent, onMounted, ref, watch } from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import ApiService from "@/core/services/ApiService";

export default defineComponent({
  name: "manage-widgets-tv-guide",
  components: {},
  setup() {
    const store = useStore();
    const selectedEvent = ref({});
    const loadingListings = ref(0);
    const loadingWidgets = ref(0);
    const baseUrl = ApiService.vueInstance.axios.defaults.baseURL;
    const cacheBuster = ref(0);

    const widget = ref({
      id: "",
      title: "",
      startOffset: null,
      numberOfDays: null,
      numberOfRows: null,
      background: "",
      content: "",
      selectedListings: [] as number[],
    });

    const refreshListings = async function () {
      loadingListings.value++;
      try {
        await store.dispatch(Actions.REFRESH_LOCATION_TV_LISTINGS);
      } catch (e) {
        console.log(e);
      } finally {
        loadingListings.value--;
      }
    };

    const refreshWidget = async function () {
      loadingWidgets.value++;
      try {
        let response = await ApiService.get(
          store.state.AuthModule.selectedLocation.tvGuideWidget
        );
        widget.value = response.data;
        cacheBuster.value = cacheBuster.value + 1;
      } finally {
        loadingWidgets.value--;
      }
    };

    const saveWidget = async function () {
      loadingWidgets.value++;
      try {
        let data = Object.assign({}, widget.value);
        let response = await ApiService.update(
          "/api/v1/tv_guide_widgets",
          widget.value.id,
          data
        );
        widget.value = response.data;
      } catch (e) {
        console.log(e);
      } finally {
        loadingWidgets.value--;
        cacheBuster.value = cacheBuster.value + 1;
      }
    };

    const toggleListing = function (listing_id) {
      if (widget.value.selectedListings.includes(listing_id)) {
        widget.value.selectedListings = widget.value.selectedListings.filter(
          (l) => l !== listing_id
        );
      } else {
        widget.value.selectedListings.push(listing_id);
      }
    };

    watch(
      () => store.state.AuthModule.account.id,
      () => {
        return;
      }
    );

    watch(
      () => store.state.AuthModule.selectedLocation.name,
      () => {
        refreshListings();
        refreshWidget();
      }
    );

    onMounted(() => {
      setCurrentPageTitle("Event Calendar");
      refreshListings();
      refreshWidget();
    });
    return {
      store,
      selectedEvent,
      loadingListings,
      loadingWidgets,
      refreshListings,
      widget,
      toggleListing,
      saveWidget,
      baseUrl,
      cacheBuster,
    };
  },
});
